import '../scss/main.scss';
import 'bootstrap'
import 'select2'
import SimpleLightbox from "simplelightbox";

const $ = require('jquery');
global.$ = global.jQuery = $;

import './cookieconsent.js';

const DOMReady = (cb, doc) => {
    return new Promise(function (resolve) {
        if (cb && typeof cb !== 'function') {
            doc = cb;
            cb = null
        }

        var done = function done() {
            return resolve(void (cb && setTimeout(cb)))
        };

        if (['interactive', 'complete'].indexOf(document.readyState) !== -1) {
            done()
        } else {
            document.addEventListener('DOMContentLoaded', done)
        }
    })
};

DOMReady(() => {
    BrokerImageHandler();
    BurgerStatusHandler();
    MobileSearchHandler();
    BrokerSearchPageHandler();
    ChangeSearchLayout();
    ScrollTo();
    Select2NoWhispper();
    ShowBrokerContact();
    CollapsibleLinkChange();
    ModalOpener();
    AutoOpenFlashPopups();
    BrokerBioHandler();
    PageUpButton();
    new SimpleLightbox('.gallery a', { /* options */ });
});


const BrokerImageHandler = () => {

    const avatar = document.querySelector('#avatar');
    const texts = document.querySelector('#text');

    if (avatar) {
        addEventListener('scroll', () => {
            window.y = window.scrollY;
            if (y > 700) {
                avatar.classList.add('active');
                texts.classList.add('active')
            } else {
                avatar.classList.remove('active');
                texts.classList.remove('active')
            }
        });
    }

};

const BurgerStatusHandler = () => {

    const nav = document.querySelector('#toggleNav');
    const burger = document.querySelector('#burger');
    const headerNav = document.querySelector('.navbar');
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    if (!nav) {
        return;
    }


    let windowWidth = document.body.clientWidth;
    window.addEventListener('resize', () => {
        setTimeout(() => {
            windowWidth = document.body.clientWidth;
            handleFix()
        }, 400)
    });

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


    nav.addEventListener('click', () => {
        burger.classList.toggle('m-open');
        burger.classList.toggle('m-close');
        handleFix();
        if (windowWidth <= 992 && burger.classList.contains('m-close')) {
            window.y = window.scrollY;
        } else {
            if (iOS) {
                window.scrollTo(0, window.y);
            }
        }
    });

    const handleFix = () => {
        if (windowWidth <= 992 && burger.classList.contains('m-close')) {
            headerNav.classList.add('fixed');
            body.classList.add('is-fixed');
            html.classList.add('is-fixed');
            if (iOS) {
                body.classList.add('is-fixed2');
            }
        } else {
            headerNav.classList.remove('fixed');
            body.classList.remove('is-fixed');
            html.classList.remove('is-fixed');
            if (iOS) {
                body.classList.remove('is-fixed2');
            }
        }
    };


};

const MobileSearchHandler = () => {

    const btn = document.querySelector('#changeSearch');
    const layout = document.querySelector('#afterSearch');
    const postLayout = document.querySelector('#postLayout');

    if (btn) {
        btn.addEventListener('click', () => {
            layout.classList.toggle('after-search');
            postLayout.classList.toggle('col-md-4');
            postLayout.classList.toggle('col-md-8')
        });
    }

};

const ChangeSearchLayout = () => {

    const inputs = document.querySelectorAll('#searchInput');
    const btns = document.querySelectorAll('#submitButton');

    if (inputs) {
        inputs.forEach(function (input) {
            input.addEventListener('input', function () {
                if (input.value.length > 0) {
                    btns.forEach(function (btn) {
                        btn.classList.remove('hide')
                    });
                } else {
                    btns.forEach(function (btn) {
                        btn.classList.add('hide')
                    });
                }
            });
        });
    }

};

const ScrollTo = () => {
    document.querySelectorAll('a.scrollTo[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
};


const Select2NoWhispper = () => {
    $('.select2').select2({
        minimumResultsForSearch: -1,
    });
};


const BrokerSearchPageHandler = () => {

    $(".brokerSearchRegion[data-selected]").click();

    $(".brokerSearchRegion").click(function () {

        $("#brokerSearchSelectedRegion").val($(this).data("uri"));

        $(".brokerSearchCounty").each(function () {
            $(this).removeAttr("checked");
        });

        var id = $(this).data("target");
        $(id).find("input[type=checkbox]").attr("checked", "checked");

    });

};

const ShowBrokerContact = () => {
    const elements = document.querySelectorAll('[data-action="showContact"]');

    const getElementByType = (type, content) => {
        const el = document.createElement('a');
        switch (type) {
            case "url":
                el.href = content;
                el.target = "_blank";
                break;
            case "phone":
                el.href = "tel:" + content;
                break;
            case "email":
                el.href = "mailto:" + content;
        }
        el.textContent = content;
        return el;
    };

    for (const element of elements) {
        element.addEventListener('click', e => {
            for (const el of elements) {

                var targetElement = e.target || e.srcElement;

                if (targetElement && targetElement.dataset.type !== el.dataset.type) {
                    continue;
                }

                const ne = document.createElement('p');
                ne.appendChild(getElementByType(el.dataset.type, el.dataset.content));
                el.parentElement.appendChild(ne);
                el.remove();
            }
            fetch(e.target.dataset.target, {
                method: "POST"
            });
        });
    }
};

const CollapsibleLinkChange = () => {
    const elements = document.querySelectorAll('.collapse[data-url]');
    const current = document.querySelector('.collapse.show');

    if (current) {
        history.replaceState({collapseOpened: current.id}, current.querySelector('h1').textContent, current.dataset.url);
    }

    for (const element of elements) {
        $(element).on('show.bs.collapse', e => {
            history.pushState({collapseOpened: e.target.id}, e.target.querySelector('h1').textContent, e.target.dataset.url)
        });
    }

    window.addEventListener('popstate', e => {
        const state = e.state;
        if (state && state.collapseOpened) {
            $('#' + state.collapseOpened).collapse('show');
        }
    });
};

const ModalOpener = () => {
    // Add listeners to modals
    $('.modal:not(.flash)').on('show.bs.modal', (e) => {
        location.hash = e.target.id;
    }).on('hide.bs.modal', (e) => {
        location.hash = '';
    });

    const openModalByHash = () => {
        const hash = decodeURI(location.hash);
        if (typeof hash === "string" && hash !== '') {
            $(hash + '.modal').modal('show');
        }
    };

    // Process hash to open initial modal
    window.addEventListener('popstate', openModalByHash);
    openModalByHash();
};

const AutoOpenFlashPopups = () => {
    $('.modal.popUp.flash').modal('show');
};

const BrokerBioHandler = () => {
    const btn = document.querySelector('#read-more');
    const layout = document.querySelector('#bio');

    if(btn){
        btn.addEventListener('click', (e) => {
            e.preventDefault();
            layout.classList.add('show');
            btn.classList.add('d-none')
        });
    }
};

const PageUpButton = () => {

    const pageUpButton = document.getElementById("pageUpButton");
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            pageUpButton.style.display = "block";
        } else {
            pageUpButton.style.display = "none";
        }
    }

    pageUpButton.onclick = function () {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
    }

    scrollFunction();
};
